html {
    overflow-y: hidden;
    font-size: 62.5%;
}

@media screen and (max-width: 980px) {
    html {
        font-size: 50% !important;
    }
}

@media screen and (max-height: 640px) {
    html {
        font-size: 45% !important;
    }
    /*.button.is-large {*/
        /*height: 7.5rem !important;*/
    /*}*/
}
body {
    overflow-y: hidden;
    font-family: LLCircularWeb, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 1.5rem;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #324A5E;
}

#machine {
    height: calc(100vh - 14.1rem);
}
.button.is-large {
    font-weight: 500;
    height: 8.8rem;
    border-radius: .8rem;
    font-size: 4rem;
}
.button:hover,
.button:active,
.button:focus,
.button {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    border-color: transparent;
}
.button.is-production:hover,
.button.is-production:active,
.button.is-production:focus,
.button.is-production {
    background-color: #2979ff;
    color: white;
}
.button.is-interrupt:hover,
.button.is-interrupt:active,
.button.is-interrupt:focus,
.button.is-interrupt {
    background-color: #455a64;
    color: white;
}

#stampo {
    transform: translate(50px, 93px);
    transition: all 500ms cubic-bezier(0.645, 0.045, 0.355, 1.000); /* easeInOutCubic */
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000); /* easeInOutCubic */
    /*transition: all 500ms cubic-bezier(0.175, 0.885, 0.320, .900); !* easeInOutCubic *!*/
    /*transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, .900); !* easeInOutCubic *!*/
}
#stampo.down {
    transform: translate(50px, 165px);
}

@-webkit-keyframes slide-in-left {
  0% {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@-webkit-keyframes slide-out-right {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
      -webkit-transform: translateX(100%);
              transform: translateX(100%);
    opacity: 0;
  }
}
@keyframes slide-out-right {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    opacity: 0;
  }
}


/*#oggetto {*/
    /*animation-duration: 250ms;*/
    /*animation-delay: 0ms;*/
/*}*/
/*#oggetto-schiacciato {*/
    /*animation-duration: 250ms;*/
    /*animation-delay: 250ms;*/
/*}*/
.oggetto-enter {
    -webkit-animation: slide-in-left 250ms cubic-bezier(0.190, 1.000, 0.220, 1.000) both;
   	        animation: slide-in-left 250ms cubic-bezier(0.190, 1.000, 0.220, 1.000) both;
}
.oggetto-exit {
    -webkit-animation: slide-out-right 250ms cubic-bezier(0.190, 1.000, 0.220, 1.000) both;
   	        animation: slide-out-right 250ms cubic-bezier(0.190, 1.000, 0.220, 1.000) both;
    animation-delay: 250ms;
}
